import React from "react";
import PropTypes from "prop-types";

function Dot(props) {
  return (
    <span
      style={{
        display: "inline-block",
        height: "13px",
        width: "13px",
        borderRadius: "50%",
        backgroundColor: "white",
        marginRight: "28px",
        opacity: props.selected ? "1" : "0.3",
        transitionDuration: "300ms",
      }}
    />
  );
}

export default function IndicatorDots(props) {
  const wrapperStyle = {
    position: "absolute",
    width: "100%",
    zIndex: "100",
    top: props.titleHeight > 110 && window.innerWidth > 1500 ? "65%" : "60%",
    paddingLeft: "7%",
    // bottom: "200px",
    // textAlign: "center",
  };

  if (props.total < 2) {
    // Hide dots when there is only one dot.
    return <div style={wrapperStyle} />;
  } else {
    return (
      <div style={wrapperStyle}>
        {Array.apply(null, Array(props.total)).map((x, i) => {
          return (
            <span onClick={() => props.handleSetCurrent(i)}>
              <Dot key={i} selected={props.index === i} />
            </span>
          );
        })}
      </div>
    );
  }
}

IndicatorDots.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};
