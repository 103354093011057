import React from "react";

const EngenLogo = ({ width = 90, height = 45 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 112 57">
    <g>
      <g>
        <path
          fill="#fefefe"
          d="M55.999 0c14.91 0 30.208 6.388 30.208 17.188 0 10.8-15.299 17.187-30.208 17.187-14.91 0-30.209-6.388-30.209-17.188C25.79 6.387 41.088 0 56 0"
        />
      </g>
      <g>
        <path
          fill="#ec1651"
          d="M75.962 6.988L52.387 19.28l-4.062-2.514L65.44 7.902c-4.063-1.7-10.383-1.568-10.383-1.568-19.185.915-18.718 10.433-18.718 10.433 0 2.252 2.13 9.38 18.718 10.23 20.106-.655 21.04-10.919 18.374-13.929l6.26-3.335c3.397 3.138 3.397 7.034 3.397 7.034.068 5.064-6.594 13.826-28.031 14.48-21.382-1.244-27.11-9.352-27.176-14.48-.066-5.072 6.126-13.965 27.176-14.685 14.178-.13 20.905 4.906 20.905 4.906"
        />
      </g>
      <g>
        <path
          fill="#fff"
          d="M111.217 55.342c0 .38-.357.687-.793.687h-5.043a.833.833 0 0 1-.505-.157c-.057-.041-8.572-8.394-8.572-8.394v7.864c0 .38-.356.687-.794.687h-5.043c-.436 0-.792-.307-.792-.687V40.487c0-.382.35-.69.786-.69h5.055c.226-.009.407.052.544.187l8.528 8.353v-7.85c0-.382.35-.69.787-.69h5.053c.438 0 .789.308.789.69zm-34.544-9.395c0 .189.175.343.392.343h7.103c.436 0 .786.308.786.69v1.876c0 .378-.356.681-.791.681h-7.098c-.217 0-.392.154-.392.344v2.558c0 .19.175.344.392.344h8.761c.435 0 .785.321.785.703v1.856c0 .38-.357.687-.792.687H70.84c-.436 0-.791-.307-.791-.687V40.487c0-.382.35-.69.787-.69h14.99c.435 0 .785.308.785.69v1.847c0 .38-.35.708-.785.708h-8.76c-.218 0-.393.156-.393.346zm-16.535-1.81c0-.363-.332-.695-1.078-.94-.729-.246-1.947-.37-3.062-.37-1.115 0-2.326.124-3.054.37-.749.245-1.076.577-1.076.94v7.554c0 .36.327.692 1.076.943.728.242 1.939.367 3.054.367 1.115 0 2.337-.125 3.066-.367.745-.25 1.074-.582 1.074-.943v-1.56c0-.207-.19-.377-.425-.377h-2.857c-.471 0-.858-.34-.858-.758v-1.732c0-.42.377-.758.847-.758h9.073c.472 0 .847.338.847.758V52.2c0 2.83-5.808 4.046-10.767 4.046S45.233 55.03 45.233 52.2v-8.573c0-2.831 5.806-4.048 10.765-4.048 4.96 0 10.767 1.217 10.767 4.048v.5c0 .419-.386.757-.857.757h-4.915c-.47 0-.855-.295-.855-.711zm-18.19 11.205c0 .38-.356.687-.792.687h-5.042a.83.83 0 0 1-.503-.157c-.058-.041-8.57-8.394-8.57-8.394v7.864c0 .38-.355.687-.791.687h-5.042c-.435 0-.792-.307-.792-.687V40.487c0-.382.35-.69.786-.69h5.054c.224-.009.406.052.542.187l8.525 8.353v-7.85c0-.382.35-.69.785-.69h5.053c.437 0 .786.308.786.69zM7.414 45.947c0 .189.175.343.392.343h7.102c.436 0 .787.308.787.69v1.876c0 .378-.355.681-.791.681H7.807c-.217 0-.392.154-.392.344v2.558c0 .19.175.344.392.344h8.76c.435 0 .785.321.785.703v1.856c0 .38-.356.687-.791.687H1.58c-.434 0-.791-.307-.791-.687V40.487c0-.382.351-.69.785-.69h14.992c.435 0 .785.308.785.69v1.847c0 .38-.35.708-.785.708h-8.76c-.217 0-.392.156-.392.346z"
        />
      </g>
    </g>
  </svg>
);

export default EngenLogo;
