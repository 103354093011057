import React, { useContext, useState, createContext } from "react";
import styles from "./Popup.module.scss";
import { EngenLogo } from "../icons";
import { NearestStationContext } from "../StationSearch/StationSearch";
import { Link } from "react-router-dom";

export const Context = createContext({
  nearStations: null,
  setNearStations: () => {},
});

export const Provider = ({ children }) => {
  const [nearStations, setNearStations] = useState([]);

  const nearStationsContext = {
    setNearStations: stations => setNearStations(stations),
    nearStations,
  };

  return <Context.Provider value={nearStationsContext}>{children}</Context.Provider>;
};

export default ({ isPopup, closePopup, denied }) => {
  const { nearStations } = useContext(Context);

  let style = {};
  if (nearStations.length <= 1 || denied) {
    style = {
      textAlign: "center",
    };
  }
  const { setNearestStation } = useContext(NearestStationContext);

  const handleStationRedirect = station => {
    const obj = {
      lng: station.longitude,
      lat: station.latitude,
    };
    setNearestStation(obj);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles["modal"]} ${isPopup ? styles.show : null}`}>
        <div className={styles["modal-dialog"]}>
          <div className={styles["modal-content"]}>
            <div className={styles["modal-header"]}>
              <span style={{ opacity: 0 }}>.</span>
              <span className={styles["modal-title"]}>
                <EngenLogo height={90} width={180} />
              </span>
              <span onClick={closePopup} title="Close" className={styles.close}>
                ×
              </span>
            </div>
            <div
              className={`${styles["modal-body"]} ${
                nearStations.length <= 1 || denied ? styles.one_station : null
              }`}>
              <span style={style} className={styles.title}>
                Your nearest stations
              </span>
              {denied ? (
                <p className={styles.denied}>
                  Geolocation Blocked. In order to display the Engen Stations near you please select
                  "allow" when the browser is requesting access to your location. If there is no
                  prompt/request, go to your browsers Settings / Site Settings and select "Allow"
                  next to "Location"
                </p>
              ) : (
                <>
                  {nearStations.length !== 0 && nearStations[0] ? (
                    nearStations.map(station => (
                      <Place
                        handleStationRedirect={handleStationRedirect}
                        key={station.id}
                        station={station}
                      />
                    ))
                  ) : (
                    <p className={styles.denied}>Loading...</p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Place = ({ station, handleStationRedirect }) => {
  return (
    <Link to="/service-station-locator">
      <div onClick={() => handleStationRedirect(station)} className={styles.place_container}>
        <div className={styles.left_container}>
          <p className={styles.heading}>{station.company_name}</p>
          <p className={styles.address}>{`${station.street_name}, ${station.city_name}${
            station.street_postal_code !== "0" ? ", " + station.street_postal_code : ""
          }`}</p>
          {station.distance ? <p className={styles.distance}>{station.distance}km</p> : null}
        </div>
        <span className={styles.arrow} />
      </div>
    </Link>
  );
};
export { Provider as NearStationsContextProvider, Context as NearStationsContext };
