import React, { useState, useRef, useEffect } from "react";
import styles from "./BannerForCarousel.module.scss";
import { Link } from "react-router-dom";
import bannerStyles from "../NavigationBanner/NavigationBanner.module.scss";

const BannerForCarousel = ({
  title,
  backgroundImage,
  underTitle,
  isCarouselBanner = false,
  subtitle,
  link,
  isPopup = false,
  loadPopup,
  setterTitleHeight,
}) => {
  const [imgLoaded, setImageLoaded] = useState(false);
  const [titleHeight, setTitleHeight] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const onImageLoad = () => {
    setImageLoaded(true);
    if (isPopup) {
      loadPopup();
    }
  };

  // const changeTitle = title => {
  //   switch (title) {
  //     case "Quick Save - Grab & Go!":
  //       return "Quick Save - \nGrab & Go!";
  //     case "Reliable advanced technology":
  //       return "Reliable advanced \ntechnology";
  //     case "Caring4Girls at Vuyolwethu":
  //       return "Caring4Girls at \nVuyolwethu";
  //     case "Find a Quality Engen product":
  //       return "Find a Quality Engen \nproduct";
  //     case "Your Journey Starts Here":
  //       return "Your Journey \nStarts Here";
  //     default:
  //       return title;
  //   }
  // };

  useEffect(() => {
    const handleResize = () => {
      setTitleHeight(titleRef.current.clientHeight);
      setterTitleHeight(titleRef.current.clientHeight);
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    setTitleHeight(titleRef.current.clientHeight);
    setterTitleHeight(titleRef.current.clientHeight);

    return () => window.removeEventListener("resize", handleResize);
    //eslint-disable-next-line
  }, []);

  const titleRef = useRef();
  // titleRef.innerHTML = changeTitle(title);

  let caclHeight = () => {
    if (titleHeight < 110 && width > 600) {
      return (height * 27) / 100 + titleHeight;
    } else {
      return (height * 17) / 100 + titleHeight;
    }
  };
  function createMarkup(title) {
    return { __html: title };
  }

  return (
    <div
      className={`${styles[underTitle ? "wrapper-with-under-title" : "wrapper"]} ${
        styles[isCarouselBanner ? "carousel-wrapper" : ""]
      }`}>
      <div className={styles.wrapperImg}>
        <img
          onLoad={onImageLoad}
          className={`${styles.background} ${imgLoaded ? styles.faded : ""}`}
          src={backgroundImage}
          alt={title}
        />
        {/* <pre className={styles.title}>{changeTitle(title)}</pre> */}
        <h1
          ref={titleRef}
          style={
            titleHeight > 110 && width > 600
              ? { background: "transparent", top: "17%" }
              : { background: "transparent", top: "27%" }
          }
          className={bannerStyles.title}
          dangerouslySetInnerHTML={createMarkup(title)}></h1>

        {isCarouselBanner ? (
          link.includes("http") ? (
            <a rel="noopener noreferrer" target="_blank" href={link}>
              <div
                style={titleHeight > 109 && width > 600 ? { top: caclHeight + "px" } : {}}
                className={`${styles.subtitle_wrapper} ${
                  height < 830 && width > 1100 && width > 1500 && titleHeight > 120
                    ? styles.biggerMarginTop
                    : null
                } ${
                  height < 830 && width > 1100 && titleHeight <= 120 ? styles.middleMarginTop : null
                }
                  ${
                    height < 830 && width > 1100 && width < 1500 && titleHeight >= 120
                      ? styles.macbookTop
                      : null
                  }
                `}>
                <span className={styles.subtitle}>{subtitle}</span>
                <span className={styles.arrow} />
              </div>
            </a>
          ) : (
            <Link to={link}>
              <div
                className={`${styles.subtitle_wrapper} ${
                  height < 800 && width > 1100 && width > 1500 ? styles.biggerMarginTop : null
                } ${
                  height < 830 && width > 1100 && titleHeight <= 120 ? styles.middleMarginTop : null
                } ${
                  height < 830 && width > 1100 && width < 1500 && titleHeight >= 120
                    ? styles.macbookTop
                    : null
                }
                `}>
                <span className={styles.subtitle}>{subtitle}</span>
                <span className={styles.arrow} />
              </div>
            </Link>
          )
        ) : null}
      </div>

      {underTitle && (
        <div className={styles["under-title"]}>
          <span>{underTitle}</span>
        </div>
      )}
    </div>
  );
};

export default BannerForCarousel;
