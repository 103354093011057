const findClosestStations = (locations, place, googleMaps) => {
  if (locations && locations.length !== 0 && place.lat && googleMaps) {
    const closestStation = locations.sort(
      (a, b) =>
        googleMaps.geometry.spherical.computeDistanceBetween(
          new googleMaps.LatLng(parseFloat(a.latitude), parseFloat(a.longitude)),
          new googleMaps.LatLng(place.lat, place.lng)
        ) -
        googleMaps.geometry.spherical.computeDistanceBetween(
          new googleMaps.LatLng(parseFloat(b.latitude), parseFloat(b.longitude)),
          new googleMaps.LatLng(place.lat, place.lng)
        )
    );

    if (closestStation.length > 0) {
      const firstDistance = googleMaps.geometry.spherical.computeDistanceBetween(
        new googleMaps.LatLng(
          parseFloat(closestStation[0].latitude),
          parseFloat(closestStation[0].longitude)
        ),
        new googleMaps.LatLng(place.lat, place.lng)
      );
      const secondDistance = googleMaps.geometry.spherical.computeDistanceBetween(
        new googleMaps.LatLng(
          parseFloat(closestStation[1].latitude),
          parseFloat(closestStation[1].longitude)
        ),
        new googleMaps.LatLng(place.lat, place.lng)
      );
      const thirdDistance = googleMaps.geometry.spherical.computeDistanceBetween(
        new googleMaps.LatLng(
          parseFloat(closestStation[2].latitude),
          parseFloat(closestStation[2].longitude)
        ),
        new googleMaps.LatLng(place.lat, place.lng)
      );
      Object.assign(closestStation[0], { distance: Math.round(firstDistance / 1000) });
      Object.assign(closestStation[1], { distance: Math.round(secondDistance / 1000) });
      Object.assign(closestStation[2], { distance: Math.round(thirdDistance / 1000) });

      let closestStationsPositions = [];
      if (closestStation[2]) {
        closestStationsPositions = [closestStation[0], closestStation[1], closestStation[2]];
      }
      return closestStationsPositions;
    }
    // let closestStationsPositions = [];
    // if (closestStation[2]) {
    //   closestStationsPositions = [closestStation[0], closestStation[1], closestStation[2]];
    // }

    // return closestStationsPositions;
  }
};

export default findClosestStations;
