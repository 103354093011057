import React, { useState, useEffect } from "react";

import Layout from "../../components/Layout/Layout";
import Banner from "../../components/Banner";

import { getB2bBySlug } from "../../api/b2b.api";
import styles from "./Careers.module.scss";
import Section from "../../components/Section";
import Button from "../../components/buttons/Button";

const Careers = () => {
  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await getB2bBySlug("careers");
        if (response.message === "success") {
          setCurrentPage(response.data.b2b);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const sortedSection =
    currentPage && currentPage.sections && currentPage.sections.length
      ? currentPage.sections.sort((a, b) => a.sort_order - b.sort_order)
      : [];

  return (
    <Layout withBottomPadding={false} pageTitle="Careers">
      {currentPage ? (
        <>
          <Banner
            backgroundImage={currentPage && currentPage.image ? currentPage.image.path : ""}
            title="Careers"
          />
          <div className={styles.main}>
            <Section section={sortedSection[0]}>
              <a
                style={{ display: "block", marginTop: "7rem" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://careers.engenoil.com/">
                <Button isWide={false} classes="white" text="Visit Careers Portal" />
              </a>
            </Section>
            <Section section={sortedSection[1]} />
            <Section section={sortedSection[2]} />
            <Section section={sortedSection[3]} />
            <div className={styles.imageSideFlex}>
              <Section section={sortedSection[4]} />
              <div className={styles.sideContent}>
                <Section section={sortedSection[5]} />
                <Section section={sortedSection[6]} />
              </div>
            </div>
            <Section section={sortedSection[7]} />
            <Section section={sortedSection[8]} />
          </div>
        </>
      ) : null}
    </Layout>
  );
};

export default Careers;
