import React from "react";

import styles from "./Radio.module.scss";

const Radio = ({
  name,
  id,
  color,
  className,
  label,
  value,
  checked,
  disabled,
  onClick,
  onChange,
  ...otherProps
}) => {
  const radioClassNames = [
    styles.radio,
    ...(color && color.includes("white") ? [styles["white"]] : []),

    className,
  ].join(" ");

  return (
    <div className={styles.radio}>
      <label className={styles.container} htmlFor={id}>
        {label}
        <input
          id={id}
          onChange={onChange}
          value={value}
          checked={checked}
          {...otherProps}
          className={radioClassNames}
          type="radio"
          name={name}
        />
        <span className={styles.checkmark}></span>
      </label>
    </div>
  );
};

export default Radio;
