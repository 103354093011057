import { get, post } from "../services";

export const getProductsCategories = () => get("product/categories");

export const getProductsByCategoryId = (id, page = 1) =>
  get(`category/id/${id}/products?page=${page}`);

export const getProductsByPage = (params, page = 1) =>
  get(`products/new_all?page=${page}&${params}`);

export const getLubricantProductsByPage = (params, page = 1) =>
  get(`products/lubricants/all?page=${page}&${params}`);

export const getProductBySlug = slug => get(`product/${slug}/slug`);

export const getAllTags = () => get(`tags/all`);

export const getAllTagsByFilter = filters => post(`tags/all`, filters);

export const getAllProducts = () => get(`products/all`);

export const getAllProductsByPage = (page = 1) => get(`products/all?page=${page}`);

export const getAllProductsByFilters = (filters, page = 1) =>
  post(`products/all?page=${page}`, filters);

export const getFiltersProductType = () => get(`filters/productType`);

export const getFiltersCategory = () => get(`filters/category`);

export const getFiltersBrand = () => get(`filters/brand`);

export const getFiltersLubricantType = brandId => get(`filters/lubricantType1/brand/${brandId}`);

export const getFiltersLubricantTypeById = (lubricantTypeId, brandId) =>
  get(`filters/lubricantType2/${lubricantTypeId}/brand/${brandId}`);

export const getFiltersChemicalType = () => get(`filters/chemicalType1`);

export const getFiltersChemicalTypeById = chemicalTypeId =>
  get(`filters/chemicalType2/${chemicalTypeId}`);

export const getFiltersChemicalType3ById = chemicalTypeId =>
  get(`filters/chemicalType3/${chemicalTypeId}`);
