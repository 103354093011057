const recursivelyFindAndAdd = (
  array,
  arrayToSet,
  fieldToSet,
  identifierField,
  identifier,
  { ...otherFields }
) => {
  const newArray = array;

  for (const item of newArray) {
    if (item[identifierField] === identifier) {
      item[fieldToSet] = arrayToSet;

      if (otherFields) {
        Object.keys(otherFields).forEach(field => {
          item[field] = otherFields[field];
        });
      }

      break;
    } else if (fieldToSet in item) {
      recursivelyFindAndAdd(item[fieldToSet], arrayToSet, fieldToSet, identifierField, identifier, {
        ...otherFields,
      });
    } else continue;
  }

  return newArray;
};

export default recursivelyFindAndAdd;
