import React, { useState, useEffect } from "react";
import styles from "./ChatBot.module.scss";
import { ReactComponent as Close } from "../../static/images/close.svg";
import logo from "../../static/images/chatbot-icon.png";
import speechBubble from "../../static/images/SpeechBubble.png";
import { Link } from "react-router-dom";
import moment from "moment";

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const localDate = localStorage.getItem("date");
    const currentDate = moment().format("dddd");
    if (!localDate || localDate !== currentDate) {
      localStorage.setItem("date", currentDate);
      setTimeout(() => {
        setIsOpen(true);
      }, 4000);
    }
  }, []);

  return (
    <div className={styles.container}>
      {isOpen ? (
        <div className={styles.chatbot}>
          <div className={styles.header}>
            <div className={styles.header__bot_credentials}>
              {/* <img
                style={{ borderRadius: "50%" }}
                src={logo}
                alt="engen"
                height="50px"
                width="50px"
              /> */}
              <span>EngenBot</span>
            </div>
            <div onClick={() => setIsOpen(false)} className={styles.header__close_button}>
              <Close />
            </div>
          </div>

          <div className={styles.date}>
            <span>{moment().format("LT")}</span>
          </div>

          <div className={styles.conversation}>
            <div className={styles.message}>
              <img height="35px" width="35px" src={logo} alt="engen" />
              <div className={styles.message__body_container}>
                <div className={styles.message__body_bot}>EngenBot</div>
                <div className={styles.message__body}>
                  <span>Hey, what are you looking for?</span>
                </div>
              </div>
            </div>

            <div className={styles.options_container}>
              <Option link="/products" text="Products" />
              <Option link="/business-solutions/aviation" text="Aviation" />
              <Option link="motorists/franchising" text="Franchising" />
            </div>
          </div>
          <div className={styles.footer}>
            <span>ChatBot by Engen</span>
          </div>
        </div>
      ) : null}
      <div onClick={() => setIsOpen(!isOpen)} className={styles.switch_icon}>
        <span>
          <img src={speechBubble} alt="engen" height="40px" />
        </span>
      </div>
    </div>
  );
};

const Option = ({ link, text }) => (
  <Link to={link}>
    <div className={styles.option}>
      <span>{text}</span>
    </div>
  </Link>
);

export default ChatBot;
