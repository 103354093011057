import React, { useState } from "react";
import styles from "./Banner.module.scss";
import { Link } from "react-router-dom";

const Banner = ({
  title,
  backgroundImage,
  underTitle,
  isCarouselBanner = false,
  subtitle,
  link,
  isPopup = false,
  loadPopup,
}) => {
  const [imgLoaded, setImageLoaded] = useState(false);

  const onImageLoad = () => {
    setImageLoaded(true);
    if (isPopup) {
      loadPopup();
    }
  };

  return (
    <div
      className={`${styles[underTitle ? "wrapper-with-under-title" : "wrapper"]} ${
        styles[isCarouselBanner ? "carousel-wrapper" : ""]
      }`}>
      <div className={styles.wrapperImg}>
        <img
          onLoad={onImageLoad}
          className={`${styles.background} ${imgLoaded ? styles.faded : ""}`}
          src={backgroundImage}
          alt={title}
        />
        <h1 className={styles.title}>{title}</h1>
        {isCarouselBanner ? (
          link.includes("http") ? (
            <a rel="noopener noreferrer" target="_blank" href={link}>
              <div className={styles.subtitle_wrapper}>
                <span className={styles.subtitle}>{subtitle}</span>
                <span className={styles.arrow} />
              </div>
            </a>
          ) : (
            <Link to={link}>
              <div className={styles.subtitle_wrapper}>
                <span className={styles.subtitle}>{subtitle}</span>
                <span className={styles.arrow} />
              </div>
            </Link>
          )
        ) : null}
      </div>

      {underTitle && (
        <div className={styles["under-title"]}>
          <span>{underTitle}</span>
        </div>
      )}
    </div>
  );
};

export default Banner;
