const africanCountries = [
  { lat: -12.5, lng: 18.5, country_code: "AO" },
  { lat: -3.5, lng: 30, country_code: "BI" },
  { lat: 9.5, lng: 2.25, country_code: "BJ" },
  { lat: 13, lng: -2, country_code: "BF" },
  { lat: -22, lng: 24, country_code: "BW" },
  { lat: 7, lng: 21, country_code: "CF" },
  { lat: 8, lng: -5, country_code: "CI" },
  { lat: 6, lng: 12, country_code: "CM" },
  { lat: 0, lng: 25, country_code: "CD" },
  { lat: -1, lng: 15, country_code: "CG" },
  { lat: 11.5, lng: 43, country_code: "DJ" },
  { lat: 28, lng: 3, country_code: "DZ" },
  { lat: 27, lng: 30, country_code: "EG" },
  { lat: 15, lng: 39, country_code: "ER" },
  { lat: 24.5, lng: -13, country_code: "EH" },
  { lat: 8, lng: 38, country_code: "ET" },
  { lat: -1, lng: 11.75, country_code: "GA" },
  { lat: 8, lng: -2, country_code: "GH" },
  { lat: 11, lng: -10, country_code: "GN" },
  { lat: 13.46666666, lng: -16.56666666, country_code: "GM" },
  { lat: 12, lng: -15, country_code: "GW" },
  { lat: 2, lng: 10, country_code: "GQ" },
  { lat: 1, lng: 38, country_code: "KE" },
  { lat: 6.5, lng: -9.5, country_code: "LR" },
  { lat: 25, lng: 17, country_code: "LY" },
  { lat: -29.5, lng: 28.5, country_code: "LS" },
  { lat: 32, lng: -5, country_code: "MA" },
  { lat: 17, lng: -4, country_code: "ML" },
  { lat: -18.25, lng: 35, country_code: "MZ" },
  { lat: 20, lng: -12, country_code: "MR" },
  { lat: -13.5, lng: 34, country_code: "MW" },
  { lat: -22, lng: 17, country_code: "NA" },
  { lat: 16, lng: 8, country_code: "NE" },
  { lat: 10, lng: 8, country_code: "NG" },
  { lat: -2, lng: 30, country_code: "RW" },
  { lat: 15, lng: 30, country_code: "SD" },
  { lat: 14, lng: -14, country_code: "SN" },
  { lat: 8.5, lng: -11.5, country_code: "SL" },
  { lat: 10, lng: 49, country_code: "SO" },
  { lat: 7, lng: 30, country_code: "SS" },
  { lat: 1, lng: 7, country_code: "ST" },
  { lat: -26.5, lng: 31.5, country_code: "SZ" },
  { lat: 15, lng: 19, country_code: "TD" },
  { lat: 8, lng: 1.16666666, country_code: "TG" },
  { lat: 34, lng: 9, country_code: "TN" },
  { lat: -6, lng: 35, country_code: "TZ" },
  { lat: 1, lng: 32, country_code: "UG" },
  { lat: -29, lng: 24, country_code: "ZA" },
  { lat: -15, lng: 30, country_code: "ZM" },
  { lat: -20, lng: 30, country_code: "ZW" },
];

export default africanCountries;
